// -*- mode: RJSX; js-indent-level: 2; -*-

import React, { Suspense, useEffect } from 'react';
import { ThemeProvider, CircularProgress } from '@material-ui/core';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Api from './Api';

const trackerTheme = responsiveFontSizes(createMuiTheme({
  palette: {
    primary: {
      main: '#908873',
    },
    secondary: {
      main: '#ad0d12',
    },
  }
}));

const Page = () => {
  useEffect(() => {
    Api.getRedirectURL(window.location.search).then((res) => {
      if (res?.url) {
        window.location = res.url;
      }
    });
  }, []);

  return (
    <div style={{width: '100vw', marginLeft: '2em', marginTop: '2em'}}>
      <CircularProgress />
    </div>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={trackerTheme}>
      <div>
        <Suspense fallback={<div/>}>
          <Page />
        </Suspense>
      </div>
    </ThemeProvider>
  );
};

export default App;
