import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import lang_en from './lang-en.json';
import lang_fi from './lang-fi.json';
import lang_sv from './lang-sv.json';
import lang_no from './lang-no.json';

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources: {
        en: { translation: lang_en },
        fi: { translation: lang_fi },
        sv: { translation: lang_sv },
        no: { translation: lang_no },
    },
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    debug: process.env.NODE_ENV !== 'production',
});
