// -*- mode: RJSX; js-indent-level: 2; -*-

import axios from 'axios';

var knownRegion = {};

const prefix = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '';

async function getRepertoire(lang, forcedRegion) {
  try {
    if (forcedRegion) {
      knownRegion = {
        region: forcedRegion
      };
    }
    const resp = await axios.get(prefix+'/api/information', { params: { lang, ...knownRegion }});
    knownRegion = {
      region: resp.data.region
    };
    return resp.data;
  } catch(e) {
    return {
      region: 'other',
    };
  }
}

async function createOrder(cart, contact, license, simNumber, language, fromApp) {
  try {
    const resp = await axios.post(prefix+'/api/payment/create', {
      customer: contact,
      license: license || null,
      sim: simNumber || null,
      language: language,
      fromApp: fromApp || null,
      cart: [
        ...(cart.licenseType ? [cart.licenseType.id]: []),
        ...cart.options.map((item) => item.id)
      ],
    });
    return {
      token: resp.data.token,
      url: resp.data.url,
    };
  } catch (e) {
    return {};
  }
}

async function orderStatus(id) {
  try {
    const resp = await axios.get(prefix+'/api/payment/status', { params: { number: id }});
    return resp.data;
  } catch (e) {
    return null;
  }
}

async function searchLicense(key, region, lang) {
  try {
    const resp = await axios.get(prefix+'/api/license', { params: { key, region, lang }});
    return resp.data;
  } catch(e) {
    return null;
  }
}

async function searchLegacy(data, region, lang) {
  try {
    const resp = await axios.get(prefix+'/api/legacy-data', { params: { data, region, lang }});
    return resp.data;
  } catch(e) {
    return null;
  }
}

async function searchSIM(number, customer, region, lang) {
  try {
    const resp = await axios.get(prefix+'/api/sim', { params: { number, customer, region, lang }});
    return resp.data;
  } catch(e) {
    return null;
  }
}

async function getRedirectURL(query) {
  try {
    const resp = await axios.get(prefix+"/api/redirect-url" + query);
    return resp.data;
  } catch(e) {
    return null;
  }
}

const Api = {
  getRepertoire,
  createOrder,
  orderStatus,
  searchLicense,
  searchLegacy,
  searchSIM,
  getRedirectURL,
};

export default Api;
